import TypeTemplate from '@/modules/questionnaire/components/steps/photo-analysis/pimples/type/TypeTemplate';

import { ACNE_TYPE, NODULE_COUNT } from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/Pimples/Type/TypeTemplate',
  component: TypeTemplate
};

const createStory = props => () => ({
  components: { TypeTemplate },
  storyProps: props,
  wrapperStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template:
    '<div :style="$options.wrapperStyles"><type-template v-bind="$options.storyProps" /></div>'
});

export const Base = createStory({
  acneTypes: [ACNE_TYPE.PUSTULES, ACNE_TYPE.PAPULES]
});

export const Nodules = createStory({
  hasNodules: true,
  acneTypes: [ACNE_TYPE.NODULES, ACNE_TYPE.PUSTULES],
  concurrentNodules: NODULE_COUNT.TWO
});
